import React from 'react';
// eslint-disable-next-line import/order
import ReactDOM from 'react-dom';

import './index.css';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './UserContext';
import { ManncoinTheme } from './Theme/ManncoinTheme';
import { SocketContextProvider } from './SocketContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ManncoinTheme}>
      <UserContextProvider>
        <SocketContextProvider>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <App />
          </SnackbarProvider>
        </SocketContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
