import { Button, Paper } from '@material-ui/core';
import { DataGrid, PageChangeParams, RowModel, RowParams } from '@material-ui/data-grid';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { API_ENDPOINT } from '../../Constants';
import { fromSotoshi } from '../../Utils';

type AirdropListItem = {
  id: string;
  started: null | string;
  finished: null | string;
  created: string | Date;
  amount: string;
  reference: string;
  status: string;
  label: string;
};

type ServerResponse = {
  properties: { offset: number; total: number; limit: number };
  entities: AirdropListItem[];
};

async function getRemoteData(page: number, limit: number): Promise<ServerResponse> {
  const offset = limit * page - limit;
  const res = await fetch(`${API_ENDPOINT}/airdrop?offset=${offset}&limit=${limit}`);
  const data = await res.json();
  return data as ServerResponse;
}

export const AirdropList: FC = () => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offset, setOffset] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState(20);
  const [rows, setRows] = useState<AirdropListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(offset / limit + 1);
  const rowHeight = 40;
  useEffect(() => {
    console.log(`offset: ${offset}  limit: ${limit}`);
    let active = true;
    (async () => {
      setLoading(true);
      const data = await getRemoteData(page, limit);
      setTotal(data.properties.total);
      setRows(
        data.entities.map((i) => {
          //i.created = new Date(i.created)
          i.amount = fromSotoshi(parseInt(i.amount, 10)).toFixed(8);
          return i;
        }),
      );
      setLoading(false);
    })();
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      active = false;
    };
  }, [page, limit]);
  const handlePageChange = (params: PageChangeParams) => {
    console.log(` handlign page change offset: ${offset}  limit: ${limit}`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newOffset = limit * params.page - limit;
    console.log(`${limit} * ${params.page} - ${limit}`);
    history.push(`/airdrop?limit=${limit}&offset=${limit * params.page - limit}`);
    setPage(params.page);
  };
  return (
    <div style={{ flexGrow: 1 }}>
      <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
        <Button onClick={() => history.push('/airdrop/new')} variant="contained" color="secondary">
          Create Airdrop
        </Button>
        <div style={{ display: 'flex', minHeight: `${rowHeight * limit + 112}px` }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={rows as RowModel[]}
              columns={[
                { field: 'created', headerName: 'Created', flex: 1, type: 'dateTime' },
                { field: 'label', headerName: 'Label', flex: 1, type: 'string' },
                { field: 'reference', headerName: 'Reference', flex: 1, type: 'string' },
                { field: 'amount', headerName: 'Amount', flex: 1, type: 'number' },
                { field: 'status', headerName: 'status', flex: 1, type: 'string' },
              ]}
              pageSize={limit}
              rowCount={total}
              paginationMode="server"
              onPageChange={handlePageChange}
              loading={loading}
              rowHeight={rowHeight}
              onRowClick={(params: RowParams) => {
                history.push(`/airdrop/${params.row.id}`);
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};
