import { Button, CircularProgress, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';

import { API_ENDPOINT } from '../Constants';

type Props = {
  address: string;
  status: string;
  onUpdate: () => void;
};

export const KycStatusUpdate: FC<Props> = ({ address, status, onUpdate }: Props) => {
  const [value, setValue] = useState(status);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState('');
  const handleChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    setValue(evt.target.value as string);
  };
  const updateStatus = () => {
    setUpdating(true);
    setError('');
    fetch(`${API_ENDPOINT}/account/${address}`, {
      method: 'PATCH',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ kycStatus: value }),
    })
      .then((res) => res.json())
      .catch((err) => {
        setError(err);
      })
      .then((json) => {
        if (json && json.success) {
          onUpdate();
        } else {
          setError(json ? json.message : 'Failed!');
        }
      })
      .finally(() => {
        setUpdating(false);
      });
  };
  if (updating) return <CircularProgress size={26} color="secondary" />;
  return (
    <Grid container>
      <FormControl variant="outlined">
        <Select value={value} onChange={handleChange} SelectDisplayProps={{ style: { padding: '.5rem 2rem .5rem .5rem' } }}>
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="deleted">Deleted</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="invalid">Invalid</MenuItem>
          <MenuItem value="approved">Approved</MenuItem>
        </Select>
      </FormControl>
      {value !== status && (
        <Button variant="contained" color="secondary" onClick={updateStatus}>
          Update
        </Button>
      )}
      {error.length > 0 && <Typography>{error}</Typography>}
    </Grid>
  );
};
