import { createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import { CreateNote } from './CreateNote';
import { NoteList } from './NoteList';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(10),
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

type Props = {
  referenceType: string;
  referenceId: string;
};

export const Note: FC<Props> = ({ referenceId, referenceType }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CreateNote referenceId={referenceId} referenceType={referenceType} />
      <NoteList referenceId={referenceId} referenceType={referenceType} />
    </div>
  );
};
