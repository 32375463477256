import { createMuiTheme } from '@material-ui/core';

export const BusinessColors = {
  // primary: '#424C4F',
  // primaryContrast: '#E50028',
  // secondary: '#E50028',
  // secondaryContrast: '#FFFFFF',
  primary: '#E50028',
  primaryContrast: '#424C4F',
  secondary: '#424C4F',
  secondaryContrast: '#E50028',
};

export const CustomerColors = {
  primary: '#FFF200',
  primaryContrast: '#299FFF',
  secondary: '#299FFF',
  secondaryContrast: '#000000',
};
export const primaryColor = '#424C4F';
export const primaryContrastTextColor = '#FFFFFF';
export const secondaryColor = '#FEF200';
export const secondaryContrastTextColor = '#000000';

export const ManncoinTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: primaryContrastTextColor,
    },
    secondary: {
      main: secondaryColor,
      contrastText: secondaryContrastTextColor,
    },
  },
  props: {
    MuiTextField: {},
  },
  spacing: 1,
});

export const ManncoinBusinessTheme = createMuiTheme({
  palette: {
    primary: {
      main: BusinessColors.primary,
      contrastText: BusinessColors.primaryContrast,
    },
    secondary: {
      main: BusinessColors.secondary,
      contrastText: BusinessColors.secondaryContrast,
    },
  },
  props: {
    MuiTextField: {},
  },
  spacing: 1,
});

export const ManncoinCustomerTheme = createMuiTheme({
  palette: {
    primary: {
      main: CustomerColors.primary,
      contrastText: CustomerColors.primaryContrast,
    },
    secondary: {
      main: CustomerColors.secondary,
      contrastText: CustomerColors.secondaryContrast,
    },
  },
  props: {
    MuiTextField: {},
  },
  spacing: 1,
});
