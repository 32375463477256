import { AppBar, Button, Grid, Tab, Tabs, ThemeProvider, createStyles, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, ReactNode, useState } from 'react';

import { API_ENDPOINT } from '../Constants';
import { ManncoinBusinessTheme, ManncoinCustomerTheme } from '../Theme/ManncoinTheme';

import { BusinessForm } from './Business/BusinessForm';
import { CustomerForm } from './Customer/CustomerForm';
import { Documents } from './Documents';
import { Note } from './Note/Notes';

type StatusType = 'pending' | 'approved' | 'invalid' | 'deleted';

type Props = {
  address: string;
  statusChange?: (status: StatusType) => void;
  type?: 'business' | 'customer';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdated?: (data: { [key: string]: any }) => void;
};

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const useTabPanelStyles = makeStyles(() => createStyles({ root: { minHeight: '41rem', width: '100%' } }));

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }: TabPanelProps) => {
  const classes = useTabPanelStyles();
  return (
    <div className={classes.root} role="tabpanel" hidden={value !== index} id={`customer-details-tabpanel-${index}`} aria-labelledby={`customer-details-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
};

function makeTabProps(index: number) {
  return {
    id: `details-tab-${index}`,
    'aria-controls': `details-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    button: { marginLeft: '1rem' },
  }),
);

export const PendingDetails: FC<Props> = ({ address, type = 'customer', onUpdated }: Props) => {
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const [value, setValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const changeStatus = (status: StatusType) => async () => {
    if (!updating) {
      setUpdating(true);
      const res = await fetch(`${API_ENDPOINT}/account/${address}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({ kycStatus: status }),
      });
      if (res.ok) {
        enqueueSnackbar('Status Updated', { variant: 'success' });
        if (onUpdated) onUpdated({ kycStatus: status });
      } else {
        enqueueSnackbar('Failed to updated status', { variant: 'error' });
      }
      setUpdating(false);
    }
  };
  const handleTabChange = (evt: ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={type === 'customer' ? ManncoinCustomerTheme : ManncoinBusinessTheme}>
      <Grid container onClick={(e) => e.stopPropagation()}>
        <Grid container direction="row">
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Button className={classes.button} onClick={changeStatus('deleted')}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.button} onClick={changeStatus('invalid')}>
              Invalid
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" className={classes.button} onClick={changeStatus('approved')}>
              Approve
            </Button>
          </Grid>
        </Grid>
        <AppBar position="relative" style={{ marginTop: '1rem' }} color="primary">
          {console.log(value)}
          {type === 'business' ? (
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="Business" {...makeTabProps(0)} />
              <Tab label="Personal" {...makeTabProps(1)} />
              <Tab label="Documents" {...makeTabProps(2)} />
              <Tab label="Notes" {...makeTabProps(3)} />
            </Tabs>
          ) : (
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="Personal" {...makeTabProps(0)} />
              <Tab label="Documents" {...makeTabProps(1)} />
              <Tab label="Notes" {...makeTabProps(2)} />
            </Tabs>
          )}
        </AppBar>
        {type === 'business' ? (
          <>
            <TabPanel value={value} index={0}>
              <BusinessForm address={address} onUpdated={(data) => (onUpdated ? onUpdated(data) : null)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CustomerForm address={address} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Documents address={address} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Note referenceType="customer" referenceId={address} />
            </TabPanel>
          </>
        ) : (
          <>
            {' '}
            <TabPanel value={value} index={0}>
              <CustomerForm address={address} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Documents address={address} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Note referenceType="customer" referenceId={address} />
            </TabPanel>
          </>
        )}
      </Grid>
    </ThemeProvider>
  );
};
