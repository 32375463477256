import { CircularProgress, Grid, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { API_ENDPOINT } from '../../Constants';
import { PendingDetails } from '../Pending';

type pendingResponse = {
  DOB: string;
  address: string;
  address1: string;
  address2: string;
  companyRegisteredName: string;
  companyRegistrationNumber: string;
  country: string;
  created: string;
  dateofIncorporation: string;
  email: string;
  firstname: string;
  id: string;
  kycStatus: string;
  lastname: string;
  postcode: string;
  registrationIP: string;
  tradingName: string;
};

type StatusType = 'approved' | 'invalid' | 'pending' | 'deleted';

type Props = {
  status?: StatusType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { flexGrow: 1, display: 'flex', padding: '1rem' },
    scrollContainer: { maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 6rem)`, flexGrow: 1 },
    scrollArea: { flexGrow: 1, overflow: 'auto', minHeight: 0, maxHeight: '100%' },
    pagination: { marginTop: '1rem' },
  }),
);

type SummaryListingProps = {
  title: string;
  subtitle: string;
  subtitle2?: string;
  address: string;
  onStatusChange?: (status: StatusType) => void;
};

const useSummaryListingStyle = makeStyles(() => createStyles({ root: { padding: '1rem', cursor: 'pointer', marginBottom: '1rem', marginRight: '1rem' } }));

export const PendingSummaryListing: FC<SummaryListingProps> = ({ title, subtitle, address, subtitle2, onStatusChange }: SummaryListingProps) => {
  const classes = useSummaryListingStyle();
  const [data, setData] = useState({ title, subtitle, address });
  const [open, setOpen] = useState(false);
  return (
    <Paper className={classes.root}>
      <Grid container direction="column" onClick={() => setOpen(!open)}>
        <Typography variant="h5">{data.title}</Typography>
        <Typography variant="subtitle1">{data.subtitle}</Typography>
        {subtitle2 && <Typography variant="subtitle1">{subtitle2}</Typography>}
        <Typography variant="subtitle2">{data.address}</Typography>
        {open && (
          <PendingDetails
            address={data.address}
            type="business"
            onUpdated={(updated) => {
              if (updated['tradingName'] !== undefined || updated['companyRegisteredName'] !== undefined) {
                setData({ ...data, title: `${updated.tradingName}, (${updated.companyRegisteredName})` });
              }
              if (updated['lastname'] !== undefined || updated['firstname'] !== undefined) {
                setData({ ...data, subtitle: `${updated.lastname}, ${updated.firstname}` });
              }
              if (updated['kycStatus'] !== undefined) {
                console.log(updated['kycStatus']);
                if (onStatusChange) onStatusChange(updated['kycStatus']);
              }
            }}
          />
        )}
      </Grid>
    </Paper>
  );
};
export const SummaryListing: FC<SummaryListingProps> = ({ title, subtitle, address, subtitle2 }: SummaryListingProps) => {
  const classes = useSummaryListingStyle();
  const history = useHistory();
  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="column"
        onClick={() => {
          history.push(`/business/${address}`);
        }}
      >
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        {subtitle2 && <Typography variant="subtitle1">{subtitle2}</Typography>}
        <Typography variant="subtitle2">{address}</Typography>
      </Grid>
    </Paper>
  );
};

export const ListBusinesses: FC<Props> = ({ status = 'approved' }: Props) => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [businesses, setBusinesses] = useState<pendingResponse[]>([]);
  const [properties, setProperties] = useState({ limit: 20, offset: 0, total: 0 });
  useEffect(() => {
    let active = true;
    (async () => {
      setError(null);
      setLoading(true);
      let url = `${API_ENDPOINT}/business/?offset=${offset}&limit=${limit}`;
      if (status) {
        url = `${url}&q=${status}`;
      }
      const res = await fetch(url);
      if (active) {
        if (res.ok) {
          const data: any = await res.json();
          setBusinesses(data.entities);
          setProperties(data.properties);
        } else {
          setError('An error occured');
        }
        setRefresh(false);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [offset, limit, refresh]);
  const handlePageChange = (evt: React.ChangeEvent<unknown>, value: number) => {
    setOffset(value * limit - limit);
  };
  const handleStatusChange = (statusChangeType: StatusType) => {
    if (statusChangeType !== status) {
      setRefresh(true);
      console.log('status changed');
    }
  };
  if (error) return <Paper>{error}</Paper>;
  if (loading)
    return (
      <Grid style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <CircularProgress />
      </Grid>
    );
  if (properties.total === 0) {
    return <Paper style={{ margin: '1rem', padding: '1rem' }}>You have no businesses that are {status}</Paper>;
  }
  return (
    <div style={{ background: 'rgba(50,50,50,.2)', flexGrow: 1, display: 'flex', flexDirection: 'column', maxHeight: '100%', padding: '1rem', boxSizing: 'border-box' }}>
      <div className={classes.scrollContainer}>
        <div className={classes.scrollArea}>
          {businesses.map((business: any, index) => {
            const summaryProps: SummaryListingProps = {
              title: `${business.tradingName}, (${business.companyRegisteredName})`,
              subtitle: `${business.lastname}, ${business.firstname}`,
              subtitle2: business.email,
              address: business.address,
            };
            return status === 'pending' ? (
              <PendingSummaryListing key={index} {...summaryProps} onStatusChange={handleStatusChange} />
            ) : (
              <SummaryListing key={index} {...summaryProps} />
            );
          })}
        </div>
      </div>
      <Pagination
        page={page}
        onChange={handlePageChange}
        count={Math.ceil(properties.total / properties.limit)}
        className={classes.pagination}
        disabled={Math.ceil(properties.total / properties.limit) <= 1}
      ></Pagination>
    </div>
  );
};
