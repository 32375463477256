import { Button, Chip, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { API_ENDPOINT } from '../../Constants';
import { CustomerAutoSearchResult, FindCustomerAutoComplete } from '../FindCustomerAutocomplete';

type AirDropData = {
  id: number;
  label: string;
  started?: Date;
  finished?: Date;
  created: Date;
  amount: string;
  reference: string;
  status: string;
  all?: boolean;
};

type AirDropDetail = {
  id: number;
  airdrop_id: number;
  address: string;
  status: string;
  txid?: string;
};

export const AirdropDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const isNew = id === 'new';
  const [loading, setLoading] = useState<boolean>(id !== 'new');
  const [airdropDetails, setAirdropDetails] = useState<AirDropDetail[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<AirDropData>({
    id: -1,
    label: '',
    created: new Date(),
    amount: '',
    reference: '',
    status: '',
    all: true,
  });
  useEffect(() => {
    if (!isNew && loading) {
      fetch(`${API_ENDPOINT}/airdrop/${id}`)
        .then((res) => res.json())
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(({ airdrop, airdrop_details }: any) => {
          console.log(airdrop);
          setData(airdrop);
          setAirdropDetails(airdrop_details);
          setLoading(false);
        });
    }
  });
  const handleSelect = (customer: CustomerAutoSearchResult) => {
    if (airdropDetails.filter((i) => i.address === customer.address).length === 0) {
      setAirdropDetails([...airdropDetails, { id: -1, airdrop_id: -1, address: customer.address, status: 'pending' }]);
      if (data.all) setData({ ...data, all: false });
    }
  };
  if (loading)
    return (
      <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="primary" />
      </div>
    );
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    const { label, amount, reference, status, all } = data;
    if (isNew) {
      fetch(`${API_ENDPOINT}/airdrop`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          label,
          amount,
          reference,
          all,
          status,
          details: airdropDetails,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            enqueueSnackbar(`Airdrop created`, { variant: 'success' });
            history.push('/airdrop');
          }
        });
    } else {
      enqueueSnackbar(`Not implemented`, { variant: 'warning' });
    }
    console.log(data);
    console.log(airdropDetails);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Paper style={{ padding: '1rem' }}>
        <Typography variant="h5">{isNew ? 'New Airdrop' : data.label}</Typography>
        <div style={{ display: 'flex', paddingTop: '1rem' }}>
          <div style={{ flexBasis: '50%', padding: '.5rem' }}>
            <Grid container direction="column" spacing={10}>
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  label="Label"
                  value={data.label}
                  placeholder="Label"
                  onChange={(evt) => {
                    setData({ ...data, label: evt.currentTarget.value });
                  }}
                  disabled={!isNew}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={data.amount}
                  label="Amount (in sotoshis)"
                  placeholder="Amount (in sotoshis)"
                  onChange={(evt) => {
                    setData({ ...data, amount: evt.currentTarget.value });
                  }}
                  disabled={!isNew}
                  required
                  fullWidth
                />
                <div>{!isNaN(parseInt(data.amount)) ? (parseInt(data.amount) / 100000000).toFixed(8) + ' manncoin' : '0 manncoin'}</div>
              </Grid>
              <Grid item>
                <TextField
                  color="secondary"
                  variant="outlined"
                  value={data.reference}
                  label="Reference"
                  placeholder="Reference"
                  onChange={(evt) => {
                    setData({ ...data, reference: evt.currentTarget.value });
                  }}
                  disabled={!isNew}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ marginTop: '1rem', display: 'block' }} color="secondary">
                <InputLabel id="demo-simple-select-outlined-label" required>
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={data.status}
                  onChange={(evt) => {
                    console.log(evt);
                    setData({ ...data, status: evt.target.value as string });
                  }}
                  label="Status"
                  style={{ width: '100%' }}
                  required
                >
                  <MenuItem value="draft">Draft</MenuItem>
                  <MenuItem value="queue">Queue</MenuItem>
                  <MenuItem value="running">Running</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="error">Error</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </div>
          <div style={{ flexBasis: '50%', flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '.5rem' }}>
            <FindCustomerAutoComplete onSelected={handleSelect} />
            {!data.all ? (
              <Grid container direction="column" style={{ marginTop: '.5rem' }}>
                {' '}
                {airdropDetails.map((i, index) => (
                  <Grid item style={{ padding: '.5rem' }} key={index}>
                    <Chip
                      label={i.address}
                      onDelete={() => {
                        const newDetails = airdropDetails.filter((x) => x.address !== i.address);
                        if (newDetails.length === 0) setData({ ...data, all: true });
                        setAirdropDetails(newDetails);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>Sending to all approved customers</div>
            )}
          </div>
        </div>
        <Divider style={{ marginTop: '1rem' }} />
        <Grid container style={{ paddingTop: '1rem' }}>
          {!isNew ? (
            <Grid item>
              <Button>Delete</Button>
            </Grid>
          ) : null}
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="secondary">
              {isNew ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
