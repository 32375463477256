import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, TextField, TextFieldProps, createStyles, makeStyles } from '@material-ui/core';

import { KycDataType } from '../../@types/kyc';
import { API_ENDPOINT } from '../../Constants';

const useStyles = makeStyles(() =>
  createStyles({
    gridItem: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
  }),
);

const textFieldDefaults: TextFieldProps = {
  fullWidth: true,
  variant: 'outlined',
  color: 'secondary',
  inputProps: { style: { padding: '1rem' } },
};

type Props = {
  customer: KycDataType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdated?: (data: any) => void;
};

export const ModifyCustomerForm: FC<Props> = ({ customer, onUpdated }: Props) => {
  const classes = useStyles();
  const [data, setData] = useState<KycDataType>(customer);
  const [busy, setBusy] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [ipV4, setIpV4] = useState(true);
  useEffect(() => {
    if (data && data.registrationIP && data.registrationIP.indexOf('::FFFF:') > -1) {
      setIpV4(true);
    } else {
      setIpV4(false);
    }
  }, [ipV4, data]);
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setBusy(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: { [key: string]: any } = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(customer).forEach((key: any) => {
      if (customer[key as keyof KycDataType] !== data[key as keyof KycDataType]) {
        payload[key] = data[key as keyof KycDataType];
      }
    });
    fetch(`${API_ENDPOINT}/kyc/${customer.address}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => {
        if (onUpdated) onUpdated(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBusy(false);
      });
  };
  const handleChange = (name: string) => (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData({ ...data, [name]: evt.currentTarget.value });
  };
  useEffect(() => {
    setIsDirty(JSON.stringify(data) !== JSON.stringify(customer));
  }, [customer, data]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container style={{ marginTop: '1rem' }} direction="column">
        <Grid item className={classes.gridItem}>
          <TextField label="First Name" placeholder="Enter first name" value={data.firstname} onChange={handleChange('firstname')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Last Name" placeholder="Enter last name" value={data.lastname} onChange={handleChange('lastname')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Date of Birth" placeholder="Enter date of birth" value={data.DOB} onChange={handleChange('DOB')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Address Line 1" placeholder="Enter first line of address " value={data.address1} onChange={handleChange('address1')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Address Line 2" placeholder="Enter second line of address" value={data.address2} onChange={handleChange('address2')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Postcode" placeholder="Enter post code" value={data.postcode} onChange={handleChange('postcode')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Country" placeholder="Enter country" value={data.country} onChange={handleChange('country')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Email" placeholder="Enter email" value={data.email} onChange={handleChange('email')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            label="Registration IP"
            placeholder="Enter registration IP"
            value={() => (data.registrationIP && data.registrationIP.indexOf('::FFFF:') > -1 ? data.registrationIP.replace('::FFFF:', '') : data.registrationIP)}
            onChange={() => {
              if (data.registrationIP && data.registrationIP.indexOf('::FFFF:') > -1) {
                console.log('handle ipv6 address');
              }
              handleChange('registrationIP');
            }}
            {...textFieldDefaults}
          />
        </Grid>
        <Grid container direction="row">
          <div style={{ flexGrow: 1 }} />
          {isDirty && (
            <Button
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const newData: any = {};
                Object.keys(customer).forEach((key) => {
                  const value = customer[key as keyof KycDataType];
                  newData[key] = value || '';
                });
                setData(newData);
              }}
            >
              Reset
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit" disabled={busy}>
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
