import React, { FC, useEffect, useState } from 'react';
import { Button, TextField, createStyles, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useSocket } from '../../SocketContext';
import { useUserContext } from '../../UserContext';

type Props = {
  referenceType: string;
  referenceId: string;
};
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '1rem',
      padding: '1rem',
      display: 'flex',
    },
    inputField: {
      flexGrow: 1,
      marginRight: '1rem',
    },
  }),
);

export const CreateNote: FC<Props> = ({ referenceId, referenceType }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUserContext();
  const classes = useStyles();
  const socket = useSocket();
  const [note, setNote] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const handleSubmit = async (evt?: React.FormEvent) => {
    if (evt) evt.preventDefault();
    console.debug(`Sending Note`);
    const res = await fetch(`/api/v1/note/${referenceType}/${referenceId}`, { headers: { 'content-type': 'application/json' }, body: JSON.stringify({ note }), method: 'POST' });
    const data = await res.json();
    if (!res.ok) {
      enqueueSnackbar(data.message, { variant: 'error' });
    } else {
      setNote('');
      setIsTyping(false);
    }
    console.log(data);
  };
  useEffect(() => {
    console.log(`isTyping: ${isTyping}`);
    socket.emit(isTyping ? 'typing' : 'stopTyping', { room: `note/${referenceType}/${referenceId}`, user: user.username });
  }, [isTyping]);
  const handleKeyUp = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter' && evt.ctrlKey) {
      handleSubmit(evt);
    }
  };
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <TextField
        multiline={true}
        value={note}
        placeholder="Write a note"
        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
          if (evt.currentTarget.value.length > 0) {
            if (!isTyping) setIsTyping(true);
          } else {
            if (isTyping) setIsTyping(false);
          }
          setNote(evt.currentTarget.value);
        }}
        className={classes.inputField}
        onKeyUp={handleKeyUp}
      />
      <Button hidden={true} type="submit" color="primary" variant="contained">
        Post
      </Button>
    </form>
  );
};
