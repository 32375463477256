import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { API_ENDPOINT } from '../Constants';

export const NewUser: FC = () => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  return (
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Typography>Create new user</Typography>
      <Grid item spacing={10}>
        <TextField
          placeholder="Username"
          value={username}
          type="text"
          onChange={(evt) => {
            setUsername(evt.currentTarget.value);
          }}
        />
      </Grid>
      <Grid item spacing={10}>
        <TextField
          placeholder="Password"
          value={password}
          type="password"
          onChange={(evt) => {
            setPassword(evt.currentTarget.value);
          }}
        />
      </Grid>
      <Button
        onClick={async () => {
          if (username.length > 0 && password.length > 0) {
            const res = await fetch(`${API_ENDPOINT}/user/update-password`, {
              method: 'POST',
              body: JSON.stringify({ username, password }),
              headers: { 'content-type': 'application/json' },
            });
            if (res) {
              const data = await res.json();
              if (data.success) {
                history.push('/');
              }
            }
          } else {
            alert("Password doesn't match");
          }
        }}
      >
        Create User
      </Button>
    </Grid>
  );
};
