/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { API_ENDPOINT } from './Constants';
import { useData } from './Hooks/useData';
import { defaultUseSearch, useSearch, useSearchType } from './Hooks/useSearch';

type KycStats = {
  pending: number;
  approved: number;
  invalid: number;
  deleted: number;
};

type KycStatsResponse = {
  business: KycStats;
  customer: KycStats;
};

type UserContextType = {
  authenticated: boolean;
  login: (username: string, password: string) => Promise<LoginResponse>;
  logout: () => void;
  showDraw: boolean;
  setShowDraw: (show: boolean) => void;
  drawContents: ReactNode[];
  setDrawContents: (contents: ReactNode[]) => void;
  user: { username?: string };
  loading: boolean;
  stats: KycStatsResponse;
  refreshStats: () => void;
  search: useSearchType;
};

const userContextDefaults: UserContextType = {
  authenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (username: string, password: string) => Promise.resolve({ success: true }),
  logout: () => {},
  showDraw: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setShowDraw: (show: boolean) => {},
  drawContents: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDrawContents: (contents: ReactNode[]) => {},
  user: {},
  loading: true,
  stats: {
    customer: { pending: -1, approved: -1, deleted: -1, invalid: -1 },
    business: { pending: -1, approved: -1, deleted: -1, invalid: -1 },
  },
  refreshStats: () => {},
  search: defaultUseSearch,
};

type LoginResponse = {
  success: boolean;
  message?: string;
};

export const UserContext = createContext<UserContextType>(userContextDefaults);

export const useUserContext = (): UserContextType => useContext(UserContext);

export const UserContextProvider: FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDraw, setShowDraw] = useState(true);
  const [user, setUser] = useState({});
  const [drawContents, setDrawContents] = useState<ReactNode[]>([]);
  const search = useSearch();
  const [stats, setStats] = useState<KycStatsResponse>({
    customer: { pending: -1, approved: -1, deleted: -1, invalid: -1 },
    business: { pending: -1, approved: -1, deleted: -1, invalid: -1 },
  });
  const { data: statsData, refresh: refreshStats } = useData('/stats');
  useEffect(() => {
    if (statsData) {
      setStats(statsData as KycStatsResponse);
    }
  }, [statsData]);
  useEffect(() => {
    fetch(`${API_ENDPOINT}/status`)
      .then((res) => res.json())
      .then((data) => {
        const { setup, user } = data;
        if (setup) {
          console.log('setup done');
        }
        if (user && user.username) {
          setUser(user);
          setAuthenticated(true);
        }
      })
      .catch(() => {
        console.log(`Failed to get status from ${API_ENDPOINT}/status`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading]);
  const login = async (username: string, password: string): Promise<LoginResponse> => {
    let errorMessage;
    const res = await fetch(`${API_ENDPOINT}/login`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ username, password }),
    }).catch((err) => {
      console.log(err);
      errorMessage = 'An error occured while trying to log in';
      return null;
    });
    if (res !== null) {
      const data = await res.json();
      if (data.success) {
        setAuthenticated(true);
        setUser(data.user);
        return { success: true };
      } else {
        return {
          success: false,
          message: 'Invalid Username or Psasword',
        };
      }
    } else {
      return {
        success: false,
        message: errorMessage,
      };
    }
  };
  const logout = async () => {
    const res = await fetch(`${API_ENDPOINT}/logout`, {
      headers: { 'content-type': 'application/json' },
    }).catch(() => null);
    if (res !== null) {
      setAuthenticated(false);
    }
  };
  return (
    <UserContext.Provider
      value={{
        authenticated,
        login,
        logout,
        showDraw,
        setShowDraw,
        drawContents,
        setDrawContents,
        user,
        loading,
        stats,
        refreshStats,
        search,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
