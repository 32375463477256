import { Paper } from '@material-ui/core';
import React, { FC } from 'react';

import { ManncoinBalance } from '../Components/Manncoin/ManncoinBalance';
import { TransactionList } from '../Components/TransactionList';

export const ManncoinServer: FC = () => (
  <Paper>
    <ManncoinBalance />
    <TransactionList address="LbQWhQJ2BpmnWBpvco1d86cVLC9GvUajUL" />
  </Paper>
);
