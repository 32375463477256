import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { API_ENDPOINT } from '../Constants';
import { useUserContext } from '../UserContext';

export const UpdatePassword: FC = () => {
  const history = useHistory();
  const { user } = useUserContext();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  return (
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Typography>Update Password for {user.username}</Typography>
      <Grid item spacing={10}>
        <TextField
          placeholder="Enter New Password"
          value={password}
          type="password"
          onChange={(evt) => {
            setPassword(evt.currentTarget.value);
          }}
        />
      </Grid>
      <Grid item spacing={10}>
        <TextField
          placeholder="Confirm Password"
          value={password2}
          type="password"
          onChange={(evt) => {
            setPassword2(evt.currentTarget.value);
          }}
        />
      </Grid>
      <Button
        onClick={async () => {
          if (password === password2) {
            const res = await fetch(`${API_ENDPOINT}/user/update-password`, {
              method: 'POST',
              body: JSON.stringify({ password }),
              headers: { 'content-type': 'application/json' },
            });
            if (res) {
              const data = await res.json();
              if (data.success) {
                history.push('/');
              }
            }
          } else {
            alert("Password doesn't match");
          }
        }}
      >
        Update
      </Button>
    </Grid>
  );
};
