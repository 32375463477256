import { Chip, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, createStyles, makeStyles } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FlightIcon from '@material-ui/icons/Flight';
import React, { FC, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import { useUserContext } from '../UserContext';

const drawerWidth = 240;
const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    nested: {
      paddingLeft: theme.spacing(20),
    },
  }),
);

export const SideNav: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { stats, showDraw, setShowDraw, logout } = useUserContext();
  const { customer, business } = stats;
  const [name, setName] = useState('');
  const [customerOpen, setCustomerOpen] = useState(true);
  const [businessOpen, setBusinessOpen] = useState(true);
  useEffect(() => {
    let active = true;
    (async () => {
      const res = await fetch('/api/v1/profile');
      const data = await res.json();
      if (active) {
        const { firstname } = data;
        setName(firstname);
      }
    })();
    return () => {
      active = false;
    };
  }, []);
  return (
    <Drawer open={showDraw} anchor="left" variant="persistent" className={classes.drawer} onClose={() => setShowDraw(false)} classes={{ paper: classes.drawerPaper }}>
      <List>
        <ListItem
          button
          onClick={() => {
            history.push('/profile');
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={`Hello, ${name}`} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                setShowDraw(false);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => setCustomerOpen(!customerOpen)}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
          {customerOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={customerOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button key="kyc" component={NavLink} to="/customer/approved" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <PeopleAltIcon style={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText>Approved</ListItemText>
              <ListItemSecondaryAction>{customer.approved > 0 ? <Chip label={customer.approved} /> : null}</ListItemSecondaryAction>
            </ListItem>
            <ListItem button key="pending" component={NavLink} to="/customer/pending" className={classes.nested} activeClassName="Mui-selected" exact>
              <ListItemIcon>
                <AssignmentIndIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Pending</ListItemText>
              <ListItemSecondaryAction>{customer.pending > 0 ? <Chip label={customer.pending} color="primary" /> : null}</ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={NavLink} to="/customer/invalid" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <PeopleAltIcon style={{ color: 'gold' }} />
              </ListItemIcon>
              <ListItemText>Invalid</ListItemText>
              <ListItemSecondaryAction>{customer.invalid > 0 ? <Chip label={customer.invalid} /> : null}</ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={NavLink} to="/customer/deleted" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <PeopleAltIcon style={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText>Deleted</ListItemText>
              <ListItemSecondaryAction>{customer.deleted > 0 ? <Chip label={customer.deleted} /> : null}</ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={() => setBusinessOpen(!businessOpen)}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Businesses" />
          {businessOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={businessOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button key="kyc" component={NavLink} to="/business/approved" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <BusinessIcon style={{ color: 'green' }} />
                <ListItemSecondaryAction>{business.approved > 0 ? <Chip label={business.approved} /> : null}</ListItemSecondaryAction>
              </ListItemIcon>
              <ListItemText>Approved</ListItemText>
            </ListItem>
            <ListItem button key="pending" component={NavLink} to="/business/pending" className={classes.nested} activeClassName="Mui-selected" exact>
              <ListItemIcon>
                <BusinessCenterIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Pending</ListItemText>
              <ListItemSecondaryAction>{business.pending > 0 ? <Chip label={business.pending} color="primary" /> : null}</ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={NavLink} to="/business/invalid" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <BusinessCenterIcon style={{ color: 'gold' }} />
              </ListItemIcon>
              <ListItemText>Invalid</ListItemText>
              <ListItemSecondaryAction>{business.invalid > 0 ? <Chip label={business.invalid} /> : null}</ListItemSecondaryAction>
            </ListItem>
            <ListItem button component={NavLink} to="/business/deleted" activeClassName="Mui-selected" className={classes.nested} exact>
              <ListItemIcon>
                <BusinessCenterIcon style={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText>Deleted</ListItemText>
              <ListItemSecondaryAction>{business.deleted > 0 ? <Chip label={business.deleted} /> : null}</ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button key="airdrop" component={NavLink} to="/airdrop">
          <ListItemIcon>
            <FlightIcon />
          </ListItemIcon>
          <ListItemText>AirDrop</ListItemText>
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            logout();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};
