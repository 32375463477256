import React, { CSSProperties, FC } from 'react';

type Props = {
  style?: CSSProperties;
};

export const ManncoinLogo: FC<Props> = ({ style = { height: '10rem' } }: Props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" style={style}>
    <g>
      <path
        fill="#FFF009"
        d="M1949.8,1176.6l26.9-121.6l-212.4-57l129.2-547L814.2,191.8c-199-47.8-399.3,73.4-449.4,271.8l-53,210.1
      L94.3,623.8L67.5,740.2l218.8,51.2L262,877.2L49.5,827.3L23.3,945l211.8,53.1L104.6,1549l1077.1,257.3
      c199.3,47.6,400-72.9,451.7-271.2l54.1-207.5l217.9,49.4l26.6-119.8l-218.8-52.5l20.5-81.9L1949.8,1176.6z M1440.5,1443.4
      c-25.9,108.2-134,175.5-242.5,151l195.8-842.6L985,1540.7l-216.9-51.8L754.6,604l-201.5,831.1l-197.7-48l223-917.6
      c14.7-60.6,75.4-98,136.1-83.9L912,431.2l23,846.5l395.4-744.8l311,71L1440.5,1443.4z"
      />
    </g>
  </svg>
);
