import React, { FC, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, TextField, TextFieldProps, createStyles, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { KycDataType } from '../../@types/kyc';
import { API_ENDPOINT } from '../../Constants';

const useStyles = makeStyles(() =>
  createStyles({
    gridItem: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
  }),
);

const textFieldDefaults: TextFieldProps = {
  fullWidth: true,
  variant: 'outlined',
  color: 'primary',
  inputProps: { style: { padding: '1rem' } },
};

type Props = {
  address: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdated?: (data: any) => void;
};

export const CustomerForm: FC<Props> = ({ address, onUpdated }: Props) => {
  const classes = useStyles();
  const [original, setOriginal] = useState<KycDataType>();
  const [data, setData] = useState<KycDataType>();
  const [error, setError] = useState<string | null>(null);
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    let active = true;
    (async () => {
      const res = await fetch(`${API_ENDPOINT}/kyc/${address}`);
      if (res.ok) {
        const data = await res.json();
        if (active) {
          setOriginal(data.kyc);
          setData(data.kyc);
        }
      } else {
        setError(`An error occured while trying to load ${address}`);
      }
      setLoading(false);
    })();
    return () => {
      active = false;
    };
  }, []);
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setBusy(true);
    const payload: { [key: string]: unknown } = {};
    if (!data) return false;
    if (!original) return false;
    Object.keys(original).forEach((key: string) => {
      if (original[key as keyof KycDataType] !== data[key as keyof KycDataType]) {
        payload[key] = data[key as keyof KycDataType];
      }
    });
    fetch(`${API_ENDPOINT}/kyc/${address}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => {
        enqueueSnackbar(`Customer Details Updated`, { variant: 'success' });
        setOriginal(data);
        if (onUpdated) onUpdated(data);
      })
      .catch((err) => {
        enqueueSnackbar(`Customer Details Update Failed`, { variant: 'error' });
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => setBusy(false), 1000);
      });
  };
  const handleChange = (name: string) => (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (data) setData({ ...data, [name]: evt.currentTarget.value });
  };
  useEffect(() => {
    setIsDirty(JSON.stringify(data) !== JSON.stringify(original));
  }, [original, data]);
  if (error) return <Grid>{error}</Grid>;
  if (loading)
    return (
      <Grid container style={{ height: '100%', width: '100%' }} alignContent="center" alignItems="center" justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  if (data && original)
    return (
      <form onSubmit={handleSubmit}>
        <Grid container style={{ marginTop: '1rem' }} direction="column">
          <Grid item className={classes.gridItem}>
            <TextField label="First Name" placeholder="Enter first name" value={data.firstname} onChange={handleChange('firstname')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Last Name" placeholder="Enter last name" value={data.lastname} onChange={handleChange('lastname')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Date of Birth" placeholder="Enter date of birth" value={data.DOB} onChange={handleChange('DOB')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Address Line 1" placeholder="Enter first line of address " value={data.address1} onChange={handleChange('address1')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Address Line 2" placeholder="Enter second line of address" value={data.address2} onChange={handleChange('address2')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Postcode" placeholder="Enter post code" value={data.postcode} onChange={handleChange('postcode')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Country" placeholder="Enter country" value={data.country} onChange={handleChange('country')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Email" placeholder="Enter email" value={data.email} onChange={handleChange('email')} {...textFieldDefaults} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField label="Registration IP" placeholder="Enter registration IP" value={data.registrationIP} onChange={handleChange('registrationIP')} {...textFieldDefaults} />
          </Grid>
          <Grid container direction="row">
            <div style={{ flexGrow: 1 }} />
            {isDirty && (
              <Button
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const newData: any = {};
                  if (original) {
                    Object.keys(original).forEach((key) => {
                      const value = original[key as keyof KycDataType];
                      newData[key] = value || '';
                    });
                    setData(newData);
                  }
                }}
              >
                Reset
              </Button>
            )}
            <Button variant="contained" color="primary" type="submit" disabled={busy || !isDirty}>
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  return <Grid>No Data</Grid>;
};
