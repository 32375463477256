import React, { FC, useEffect, useState } from 'react';
import ImageIcon from '@material-ui/icons/Image';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { setTextRange } from 'typescript';

import { API_ENDPOINT } from '../Constants';

import { FileUploader } from './FileUploader';

type Props = {
  address: string;
  type: 'idDocument' | 'idSelfie' | string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
};
export const CustomerImage: FC<Props> = ({ address, type, height = '600px', minWidth = 'auto', maxHeight = '40vh', maxWidth = 'auto' }: Props) => {
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [img, setImg] = useState<HTMLImageElement | null>();
  const [src, setSrc] = useState(`${address}-${type}`);
  const [error, setError] = useState(false);
  const handleImageLoad = () => {
    setLoading(false);
  };
  const handleImageError = () => {
    setError(true);
    setLoading(false);
  };
  useEffect(() => {
    const i = new Image();
    i.onload = handleImageLoad;
    i.onerror = handleImageError;
    i.src = `/kyc-data/${address}-${type}.jpg`;
  });
  const handleDrop = (files: FileList) => {
    // eslint-disable-next-line prefer-destructuring
    const file = files[0];
    if (file) {
      setLoading(true);
      const data = new FormData();
      data.append('file', file);
      fetch(`${API_ENDPOINT}/upload/${address}/${type}`, {
        method: 'POST',
        body: data,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setError(false);
            setSrc(`${address}-${type}`);
            enqueueSnackbar('Image Uploaded', { variant: 'success' });
          } else {
            enqueueSnackbar('Failed to upload Image', { variant: 'error' });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  if (loading) {
    return (
      <div
        style={{
          minWidth,
          minHeight: height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  if (error) {
    return (
      <div
        style={{
          minWidth,
          minHeight: height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FileUploader
          style={{
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          onDrop={handleDrop}
        >
          <Button>Browse to upload image</Button>
          <ImageIcon />
          <Typography>Image Failed to load or there is no image!</Typography>
        </FileUploader>
      </div>
    );
  }
  return (
    <div style={{ height: '600px', maxWidth, maxHeight }}>
      <FileUploader style={{ height: '100%' }} onDrop={handleDrop}>
        <img
          src={`/kyc-data/${src}.jpg`}
          style={{
            height: '100%',
            width: 'auto',
            objectFit: 'cover',
            display: 'block',
            margin: 'auto',
          }}
        />
        {/* <Button>Browse to upload image</Button> */}
      </FileUploader>
    </div>
  );
};
