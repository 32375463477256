import { Chip, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { FC } from 'react';

import { primaryColor } from '../Theme/ManncoinTheme';

type Props = {
  status: 'invalid' | 'approved' | 'deleted' | 'pending';
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    approved: { backgroundColor: 'green', color: '#FFF' },
    invalid: { backgroundColor: 'gold' },
    deleted: { backgroundColor: 'red', color: '#FFF' },
    pending: { backgroundColor: primaryColor, color: '#FFF' },
  }),
);

export const AccountStatus: FC<Props> = ({ status }: Props) => {
  const classes = useStyles();
  return <Chip label={status.toUpperCase()} className={classes[status]} />;
};
