import './App.css';
import React from 'react';
import clsx from 'clsx';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { CircularProgress, Fade, Grid, Toolbar, createStyles, makeStyles } from '@material-ui/core';

import { useUserContext } from './UserContext';
import { Login } from './Components/Login';
import { Header } from './Components/Header';
import { Dashboard } from './Components/Dashboard';
import { KycPending } from './Components/Kyc/KycPending';
import { SideNav } from './Components/SideNav';
import { UpdatePassword } from './Components/UpdatePassword';
import { NewUser } from './Components/NewUser';
import { AirdropList } from './Components/Airdrop/Airdrop';
import { CustomerDetails } from './Components/CustomerDetails';
import { AirdropDetails } from './Components/Airdrop/AirdropDetails';
import { Profile } from './Components/Account/Profile';
import { CustomerListing } from './Components/Customer/CustomerListing';
import { ManncoinServer } from './Routes/ManncoinServer';
import { SearchResults } from './Components/SearchResults';
import { BusinessDetails } from './Components/Business/Business';
import { ListBusinesses } from './Components/Business/ListBusinesses';
import { ListCustomer } from './Components/Customer/ListCustomer';

const drawerWidth = 240;
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

function App(): React.ReactElement {
  const { authenticated, loading, showDraw } = useUserContext();
  const classes = useStyles();
  if (loading) {
    return (
      <Grid container justify="center" alignContent="center" style={{ height: '100vh', width: '100%' }}>
        <Grid container justify="center" wrap="wrap">
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  if (!authenticated) {
    return (
      <Fade in={true} timeout={1000}>
        <Login />
      </Fade>
    );
  }
  return (
    <Router>
      <div className={classes.root}>
        <Header />
        <SideNav />
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: showDraw,
          })}
        >
          <Toolbar />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/user/password" component={UpdatePassword} />
            <Route exact path="/user/new" component={NewUser} />
            {/* <Route exact path="/customer/pending" component={KycPending} /> */}
            <Route exact path="/customer/" component={() => <CustomerListing kycStatus="all" />} />
            <Route exact path="/customer/approved" component={() => <ListCustomer status="approved" />} />
            <Route exact path="/customer/deleted" component={() => <ListCustomer status="deleted" />} />
            <Route exact path="/customer/invalid" component={() => <ListCustomer status="invalid" />} />
            <Route exact path="/customer/pending" component={() => <ListCustomer status="pending" />} />
            <Route exact path="/customer/:address" component={CustomerDetails} />
            <Route exact path="/business/approved" component={() => <ListBusinesses status="approved" />} />
            <Route exact path="/business/deleted" component={() => <ListBusinesses status="deleted" />} />
            <Route exact path="/business/invalid" component={() => <ListBusinesses status="invalid" />} />
            <Route exact path="/business/pending" component={() => <ListBusinesses status="pending" />} />
            <Route exact path="/business/:address" component={BusinessDetails} />
            <Route exact path="/airdrop" component={AirdropList} />
            <Route exact path="/airdrop/:id" component={AirdropDetails} />
            <Route exact path="/nodes" component={ManncoinServer} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/search" component={SearchResults} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
