import { Button, CircularProgress, Grid, Menu, MenuItem, Tab, Tabs, TextField, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import { API_ENDPOINT } from '../Constants';

import { CustomerImage } from './CustomerImage';

type Props = {
  address: string;
};

interface TabPanelProps {
  index: number;
  value: number;
  address: string;
  children: React.ReactNode;
}

type FileType = {
  label: string;
  fileType: string;
  ext: string;
  address: string;
};

const TabPanel: FC<TabPanelProps> = ({ children, value, index, address }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index} id={`${address}-tabpanel-${index}`} arei-labelledby={`${address}-tab-${index}`}>
    {children}
  </div>
);

function a11yProps(address: string, index: number) {
  return {
    id: `${address}-tab-${index}`,
    'aria-controls': `${address}-tabpanel-${index}`,
  };
}
function getLabelFromType(fileType: string) {
  return fileType
    .substr(2)
    .split(/(?=[A-Z])/)
    .join(' ');
}

function parseFiles(files: string[]): FileType[] {
  return files.map((file) => {
    const [address, filename] = file.split('-');
    const [fileType, ext] = filename.split('.');
    const label = getLabelFromType(fileType);
    // eslint-disable-next-line prefer-destructuring
    return {
      label,
      fileType,
      ext,
      address,
    };
  });
}

const sortOnLabel = (a: FileType, b: FileType) => {
  const la = a.label.toUpperCase();
  const lb = b.label.toUpperCase();
  if (la < lb) return -1;
  if (la > lb) return 1;
  return 0;
};

const requiredLabels = ['Document', 'Selfie'];

const useStyles = makeStyles(() =>
  createStyles({
    fullSizeContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addDocument: {
      padding: '.5rem',
    },
  }),
);

export const Documents: FC<Props> = ({ address }: Props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState(true);
  const [labelInput, setLabelInput] = useState('');
  const [error, setError] = useState<undefined | string>();
  useEffect(() => {
    let active = true;
    (async () => {
      const res = await fetch(`${API_ENDPOINT}/document/${address}`);
      if (active) {
        setError(undefined);
        if (res.ok) {
          const data = await res.json();
          const rawFiles = parseFiles(data);
          const required: FileType[] = [];
          const other: FileType[] = [];
          rawFiles.forEach((i) => {
            if (requiredLabels.indexOf(i.label) > -1) {
              required.push(i);
            } else {
              other.push(i);
            }
          });
          if (required.length !== 2) {
            if (required.length === 0) {
              required.push({ label: 'Document', fileType: 'idDocument', ext: '', address }, { label: 'Selfie', fileType: 'idSelfie', ext: '', address });
            } else {
              requiredLabels.forEach((label) => {
                if (required.filter((i) => i.label === label).length === 0) {
                  required.push({ label, fileType: `id${label}`, ext: '', address });
                }
              });
            }
          }
          required.sort(sortOnLabel);
          other.sort(sortOnLabel);
          setFiles(required.concat(other));
        } else {
          setError(`[${res.status}] An error occured while tryign to get the documents for ${address}`);
        }
        setLoading(false);
      }
    })();
    return () => {
      active = false;
    };
  }, []);
  const handleAddImageName = (evt: React.FormEvent) => {
    evt.preventDefault();
    const parts = labelInput.split(/\s+/);
    const type = parts.map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join('');
    setFiles([...files, { label: labelInput, fileType: `id${type}`, ext: '', address }]);
    setLabelInput('');
    setTabValue(files.length);
  };
  if (loading) {
    return (
      <Grid container className={classes.fullSizeContainer}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (error) {
    return (
      <Grid container className={classes.fullSizeContainer}>
        <Typography variant="h4">{error}</Typography>
      </Grid>
    );
  }
  return (
    <div>
      <Tabs value={tabValue} onChange={(evt, val) => setTabValue(val)} color="primary">
        {files.map(({ label }, index) => (
          <Tab key={index} label={label} {...a11yProps(address, index)} />
        ))}
        <div>
          <form onSubmit={handleAddImageName}>
            <Grid container className={classes.addDocument}>
              <TextField
                placeholder="Enter Image Name"
                value={labelInput}
                onChange={(evt) => {
                  setLabelInput(evt.currentTarget.value);
                }}
              ></TextField>
              <Button aria-controls="add-image-item" aria-haspopup="true" type="submit">
                +
              </Button>
            </Grid>
          </form>
        </div>
      </Tabs>
      {files.map(({ fileType }, index) => (
        <TabPanel key={index} value={tabValue} index={index} address={address}>
          <CustomerImage address={address} type={fileType} height="600px" />
        </TabPanel>
      ))}
    </div>
  );
};
