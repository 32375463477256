import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

type Props = {
  id: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Profile: FC<Props> = ({ id }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });
  const [password, setPassword] = useState({
    password: '',
    passwordConfirm: '',
  });
  useEffect(() => {
    let active = true;
    (async () => {
      const res = await fetch('/api/v1/profile');
      const data = await res.json();
      if (active) {
        const { firstname, lastname, email } = data;
        setProfile({ firstname, lastname, email });
      }
    })();
    return () => {
      active = false;
    };
  }, []);
  const handleProfileSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    const res = await fetch('/api/v1/profile', { headers: { 'content-type': 'application/json' }, body: JSON.stringify(profile), method: 'POST' });
    if (res.ok) {
      enqueueSnackbar(`Profile updated`, { variant: 'success' });
    } else {
      enqueueSnackbar(`Profile updated failed`, { variant: 'error' });
    }
  };
  const handleTextChange = (name: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [name]: evt.currentTarget.value });
  };

  const handlePasswordSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
  };

  const handlePasswordChange = (name: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [name]: evt.currentTarget.value });
  };
  return (
    <Paper style={{ padding: '1rem' }}>
      <Typography variant="h4">Profile</Typography>
      <Grid container>
        <Grid item>
          <form onSubmit={handleProfileSubmit}>
            <Grid container direction="column" spacing={10}>
              <Grid item>
                <TextField placeholder="FirstName" variant="outlined" value={profile.firstname} onChange={handleTextChange('firstname')} />
              </Grid>
              <Grid item>
                <TextField placeholder="LastName" variant="outlined" value={profile.lastname} onChange={handleTextChange('lastname')} />
              </Grid>
              <Grid item>
                <TextField placeholder="Email" variant="outlined" value={profile.email} onChange={handleTextChange('email')} />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" type="submit">
                  Update Details
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item>
          <form onSubmit={handlePasswordSubmit}>
            <Grid container direction="column" spacing={10}>
              <Grid item>
                <TextField placeholder="Password" variant="outlined" value={password.password} onChange={handlePasswordChange('password')} />
              </Grid>
              <Grid item>
                <TextField placeholder="Confirm Password" variant="outlined" value={password.passwordConfirm} onChange={handlePasswordChange('passwordConfirm')} />
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary">
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};
