import { useState } from 'react';

import { API_ENDPOINT } from '../Constants';

export type SearchResultItemType = {
  accountType: 'business' | 'customer';
  address: string;
  created: string;
  name: string;
  status: 'approved' | 'invalid' | 'deleted' | 'pending';
};

export type SearchResultType = {
  properties: {
    offset: number;
    limit: number;
    total: number;
  };
  entities: SearchResultItemType[];
};

export type useSearchType = {
  query: string;
  setQuery: (val: string) => void;
  model: null | string;
  setModel: (val: null | string) => void;
  filters: { [key: string]: string } | null;
  setFilters: (val: { [key: string]: string }) => void;
  results: SearchResultType | null;
  loading: boolean;
  doSearch: () => void;
  changePage: (page: number) => void;
  error: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
const fakeState = (val: null | string): void => {};

export const defaultUseSearch = {
  query: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setQuery: (val: string): void => {},
  model: null,
  setModel: fakeState,
  filters: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  setFilters: (val: { [key: string]: string }): void => {},
  results: null,
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  doSearch: (): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  changePage: (page: number): void => {},
  error: '',
};

export const useSearch = (): useSearchType => {
  const [query, setQuery] = useState<string>('');
  const [model, setModel] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [params, setParams] = useState({ offset: 0, limit: 20 });
  const [results, setResults] = useState<SearchResultType | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const doSearch = async () => {
    if (!loading) {
      setError(null);
      setLoading(true);
      const res = await fetch(`${API_ENDPOINT}/search`, { method: 'POST', headers: { 'content-type': 'application/json' }, body: JSON.stringify({ q: query, ...params }) });
      console.log(res.ok);
      if (!res.ok) {
        setError(`An error occured while trying to search`);
        setLoading(false);
      }
      setResults(await res.json());
      setLoading(false);
    }
  };
  const changePage = (page: number) => {
    const offset = page - 1 * params.limit;
    setParams({ offset, limit: params.limit });
  };
  return {
    query,
    setQuery,
    model,
    setModel,
    filters,
    setFilters,
    results,
    loading,
    doSearch,
    changePage,
    error,
  };
};
