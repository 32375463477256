import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';

import { API_ENDPOINT } from '../Constants';

export type CustomerAutoSearchResult = {
  address: string;
  firstname?: string;
  lastname?: string;
  email?: string;
};

type Props = {
  onSelected: (option: CustomerAutoSearchResult) => void;
};

export const FindCustomerAutoComplete: FC<Props> = ({ onSelected }: Props) => {
  const [value, setValue] = useState<null | CustomerAutoSearchResult>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<CustomerAutoSearchResult[]>([]);

  useEffect(() => {
    let active = true;
    (async () => {
      if (inputValue === '' || inputValue.length < 3) {
        setOptions(value ? [value] : []);
        return undefined;
      }
      const response = await fetch(`${API_ENDPOINT}/customersearch?q=${inputValue.trim()}`);
      const results = (await response.json()) as CustomerAutoSearchResult[];
      if (active) setOptions(results);
    })();
    return () => {
      active = false;
    };
  }, [inputValue, value]);
  return (
    <Autocomplete
      getOptionLabel={() => ``}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        console.log(`changed to ${newValue}`);
        if (newValue !== null) onSelected(newValue);
        setValue(null);
        setInputValue('');
      }}
      style={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label="Search Customers (3 letters minimum)" variant="outlined" fullWidth color="secondary" />}
      renderOption={(option) => (
        <Grid container>
          <Grid item>
            {option.lastname ? option.lastname : '{unknown}'}, {option.firstname}, {option.email}
          </Grid>
        </Grid>
      )}
    />
  );
};
