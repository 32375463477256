import qs from 'querystring';

import { Button, Paper, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import { DataGrid, PageChangeParams, RowModel, RowParams } from '@material-ui/data-grid';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { API_ENDPOINT } from '../../Constants';

type Customer = {
  id?: string;
  address: string | null;
  firstname: string | null;
  lastname: string | null;
  DOB: string | null;
  address1: string | null;
  address2: string | null;
  postcode: string | null;
  country: string | null;
  email: string | null;
  registrationIP: string | null;
  created: string;
  kycStatus: string;
};

type ServerResponse = {
  properties: { offset: number; total: number; limit: number };
  entities: Customer[];
};

async function getRemoteData(path: string, page: number, limit: number, q: string): Promise<ServerResponse> {
  const offset = limit * page - limit;
  const url = `${API_ENDPOINT}/${path}?offset=${offset}&limit=${limit}&q=${encodeURIComponent(q)}`;
  console.log(url);
  const res = await fetch(url);
  const data = await res.json();
  return data as ServerResponse;
}

type Props = {
  kycStatus: 'all' | 'invalid' | 'pending' | 'deleted' | 'approved';
};

const paths = {
  all: '/customer',
  invalid: '/customer/invalid',
  pending: '/customer/pending',
  deleted: '/customer/deleted',
  approved: '/customer/approved',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      letterSpacing: 'normal',
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontWeight: 700,
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'}`,
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
      },
      '& .MuiPaginationItem-root': {
        borderRadius: 0,
      },
    },
  }),
);

export const CustomerListing: FC<Props> = ({ kycStatus }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { offset = 0, limit = 20, q: qParam = '' } = qs.parse(location.search.replace(/^\?/, '')) as any;
  const [q, setQ] = useState(qParam ? qParam : '');
  const [query, setQuery] = useState<string>(qParam ? qParam : '');
  const [rows, setRows] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState<number>(offset / limit + 1);
  const rowHeight = 40;

  useEffect(() => {
    console.log(`offset: ${offset}  limit: ${limit}`);
    let active = true;
    (async () => {
      setLoading(true);
      const data = await getRemoteData(paths[kycStatus], page, limit, query);
      if (active) {
        setTotal(data.properties.total);
        setRows(
          data.entities.map((i) => {
            i.id = i.address || '';
            return i;
          }),
        );
        setLoading(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [page, query, limit]);
  const handleSubmit = (evt: React.FormEvent) => {
    setQuery(q);
    evt.preventDefault();
  };
  const handlePageChange = (params: PageChangeParams) => {
    setPage(params.page);
  };
  return (
    <div style={{ flexGrow: 1, padding: '1rem' }}>
      <Paper>
        <form onSubmit={handleSubmit} style={{ textAlign: 'center', padding: '1rem' }}>
          <div style={{ alignSelf: 'middle' }}>
            <TextField
              placeholder="Search Customers"
              value={q}
              onChange={(evt) => {
                setQ(evt.currentTarget.value);
              }}
            />
            <Button type="submit">Search</Button>
          </div>
        </form>
      </Paper>
      <Paper style={{ marginTop: '2rem' }}>
        <div style={{ display: 'flex', minHeight: `${rowHeight * limit + 112}px` }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={rows as RowModel[]}
              className={classes.root}
              columns={[
                { field: 'address', headerName: 'Address', flex: 1, type: 'string' },
                { field: 'lastname', headerName: 'Lastname', flex: 1, type: 'string' },
                { field: 'firstname', headerName: 'Firstname', flex: 1, type: 'string' },
                { field: 'email', headerName: 'Email', flex: 1, type: 'string' },
                { field: 'kycStatus', headerName: 'KYC Status', flex: 1, type: 'string' },
              ]}
              pageSize={parseInt(limit, 10)}
              rowCount={total}
              paginationMode="server"
              onPageChange={handlePageChange}
              loading={loading}
              rowHeight={rowHeight}
              onRowClick={(params: RowParams) => {
                history.push(`/customer/${params.row.address}`);
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};
