import { Button, Menu, MenuItem, Tab, Tabs } from '@material-ui/core';
import React, { FC, useState } from 'react';

import { CustomerImage } from '../CustomerImage';

interface TabPanelProps {
  index: number;
  value: number;
  address: string;
  children: React.ReactNode;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, address }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index} id={`${address}-tabpanel-${index}`} arei-labelledby={`${address}-tab-${index}`}>
    {children}
  </div>
);

function a11yProps(address: string, index: number) {
  return {
    id: `${address}-tab-${index}`,
    'aria-controls': `${address}-tabpanel-${index}`,
  };
}

const fileTypes = ['idDocument', 'idSelfie', 'idProofOfAddress', 'idIsleOfMan'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultFileTypes: { [key: string]: any } = {
  idDocument: {
    label: 'Document',
  },
  idSelfie: {
    label: 'Selfie',
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const otherFileTypes: { [key: string]: any } = {
  idProofOfAddress: {
    label: 'Proof of Address',
  },
};

function parseFiles(files: string[]) {
  return files.map((file) => {
    const [address, filename] = file.split('-');
    const [fileType, ext] = filename.split('.');
    let label = fileType;
    // eslint-disable-next-line prefer-destructuring
    if (defaultFileTypes[fileType] !== undefined) label = defaultFileTypes[fileType].label;
    // eslint-disable-next-line prefer-destructuring
    if (otherFileTypes[fileType] !== undefined) label = otherFileTypes[fileType].label;
    return {
      label,
      fileType,
      ext,
      address,
    };
  });
}

function getLabelFromType(fileType: string) {
  return fileType
    .substr(2)
    .split(/(?=[A-Z])/)
    .join(' ');
}

type KycImagesTabsProps = {
  address: string;
  files: string[];
};

export const KycImagesTabs: FC<KycImagesTabsProps> = ({ address, files }: KycImagesTabsProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [addItemAnchorEl, setAddItemAnchoEl] = useState<HTMLElement | null>(null);
  const [images, setImages] = useState<string[]>(parseFiles(files).map((file) => file.fileType));
  const [otherImages, setOtherImages] = useState(
    fileTypes.filter((i) => {
      console.log(`${i}: ${images.indexOf(i) === -1}`);
      return images.indexOf(i) === -1;
    }),
  );
  const handleAddItem = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setAddItemAnchoEl(evt.currentTarget);
  };
  return (
    <div>
      <Tabs value={tabValue} onChange={(evt, val) => setTabValue(val)} color="primary">
        {images.map((image, index) => (
          <Tab key={index} label={getLabelFromType(image)} {...a11yProps(address, index)} />
        ))}
        {otherImages.length > 0 && (
          <div>
            <Button aria-controls="add-image-item" aria-haspopup="true" onClick={handleAddItem}>
              +
            </Button>
            <Menu id="add-image-item" anchorEl={addItemAnchorEl} keepMounted open={Boolean(addItemAnchorEl)} onClose={() => setAddItemAnchoEl(null)}>
              {otherImages.map((fileType, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setImages([...images, fileType]);
                    setOtherImages(otherImages.filter((i) => i !== fileType));
                  }}
                >
                  {getLabelFromType(fileType)}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </Tabs>
      {images.map((image, index) => (
        <TabPanel key={index} value={tabValue} index={index} address={address}>
          <CustomerImage address={address} type={image} height="600px" />
        </TabPanel>
      ))}
    </div>
  );
};
