import { Button, TextField } from '@material-ui/core';
import React, { FC } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

import { useUserContext } from '../UserContext';

export const SearchBox: FC = () => {
  const { search } = useUserContext();
  const history = useHistory();
  const { query, setQuery, doSearch } = search;
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    doSearch();
    history.push('/search');
  };
  return (
    <form onSubmit={handleSubmit} style={{ textAlign: 'center', padding: '1rem' }}>
      <div style={{ alignSelf: 'middle' }}>
        <TextField
          placeholder="Search"
          value={query}
          color="secondary"
          InputProps={{
            style: {
              color: '#FFF',
            },
          }}
          onChange={(evt) => {
            setQuery(evt.currentTarget.value);
          }}
        />
        <Button type="submit">
          <SearchIcon color="secondary" />
        </Button>
      </div>
    </form>
  );
};
