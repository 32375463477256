import React, { FC } from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';

import { useData } from '../Hooks/useData';

export const KycStats: FC = () => {
  const { loading, error, data } = useData('/kyc/stats');
  if ((loading || !data) && !error) {
    return <CircularProgress color="primary" />;
  }
  if (error) {
    console.log(error);
    return <Paper>An error has occured</Paper>;
  }
  const { pending, approved } = data;
  console.log(data);
  return (
    <Paper>
      <Typography>Pending: {pending}</Typography>
      <Typography>Approved: {approved}</Typography>
    </Paper>
  );
};
