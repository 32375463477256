import { DataGrid, PageChangeParams, RowModel } from '@material-ui/data-grid';
import React, { FC, useEffect, useState } from 'react';

import { API_ENDPOINT } from '../Constants';
import { fromSotoshi } from '../Utils';

interface TransactionType {
  id: number;
  txid: string;
  type: 'input' | 'output';
  date: string;
  from: string;
  to: string;
  amount: string;
  fee: string;
  reference: string;
}

interface ApiResponse<T> {
  properties: {
    limit: number;
    offset: number;
    total: number;
  };
  entities: T[];
}

type Props = {
  address: string;
  pageSize?: number;
};

async function getRemoteData(address: string, page: number, limit: number): Promise<ApiResponse<TransactionType>> {
  const offset = limit * page - limit;
  const res = await fetch(`${API_ENDPOINT}/transaction/${address}?offset=${offset}&limit=${limit}`);
  const data = await res.json();
  return data as ApiResponse<TransactionType>;
}

export const TransactionList: FC<Props> = ({ address, pageSize = 20 }: Props) => {
  const [rows, setRows] = useState<TransactionType[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const rowHeight = 40;
  useEffect(() => {
    let active = true;
    (async () => {
      console.log(`Getting page: ${page}`);
      setLoading(true);
      const data = await getRemoteData(address, page, limit);
      setTotal(data.properties.total);
      setRows(
        data.entities.map((i) => {
          i.amount = fromSotoshi(parseInt(i.amount, 10)).toFixed(8);
          i.fee = parseInt(i.fee, 10) > 0 ? fromSotoshi(parseInt(i.fee, 10)).toFixed(8) : '0';
          return i;
        }),
      );
      setLoading(false);
    })();
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      active = false;
    };
  }, [page, limit]);
  useEffect(() => {
    setLimit(pageSize);
  }, [pageSize]);
  const handlePageChange = (params: PageChangeParams) => {
    setPage(params.page);
  };
  return (
    <div style={{ display: 'flex', minHeight: `${rowHeight * limit + 112}px` }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows as RowModel[]}
          columns={[
            { field: 'date', headerName: 'Date/Time', type: 'dateTime' },
            { field: 'txid', headerName: 'Transaction ID', type: 'string' },
            { field: 'type', headerName: 'Type', type: 'string' },
            { field: 'from', headerName: 'From', type: 'string' },
            { field: 'to', headerName: 'To' },
            { field: 'reference', headerName: 'Reference', type: 'string', flex: 1 },
            { field: 'amount', headerName: 'Amount', type: 'number' },
            { field: 'fee', headerName: 'Fee', type: 'number' },
          ]}
          paginationMode="server"
          loading={loading}
          pageSize={limit}
          rowCount={total}
          rowHeight={rowHeight}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};
