import { CircularProgress, LinearProgress } from '@material-ui/core';
import React, { FC } from 'react';

import { useAvailableBalance } from '../Hooks/useAvailableBalance';
import { fromSotoshi } from '../Utils';

type props = {
  address: string;
  asSotoshi?: boolean;
  color?: 'secondary' | 'primary';
  loadingSize?: number;
};

export const CustomerAvailableBalance: FC<props> = ({ address, asSotoshi = false, color = 'primary', loadingSize = 14 }: props) => {
  const { loading, error, data } = useAvailableBalance(address);
  if (loading) return <CircularProgress color={color} size={loadingSize} />;
  if (error) return <span>ERROR</span>;
  if (data) return <span>{asSotoshi ? data.amount : fromSotoshi(data.amount).toFixed(8)}</span>;
  return <span>No Data</span>;
};
