import React from 'react';

import { secondaryColor } from '../Theme/ManncoinTheme';

export const ManncoinIcon = ({ height = '64px', width = '64px', color = secondaryColor, ...props }: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2000 2000" enableBackground="new 0 0 2000 2000" height={height} width={width} {...props}>
    <g>
      <path
        fill={color}
        d="M1949.8,1176.6l26.9-121.6l-212.4-57l129.2-547L814.2,191.8c-199-47.8-399.3,73.4-449.4,271.8l-53,210.1
 L94.3,623.8L67.5,740.2l218.8,51.2L262,877.2L49.5,827.3L23.3,945l211.8,53.1L104.6,1549l1077.1,257.3
 c199.3,47.6,400-72.9,451.7-271.2l54.1-207.5l217.9,49.4l26.6-119.8l-218.8-52.5l20.5-81.9L1949.8,1176.6z M1440.5,1443.4
 c-25.9,108.2-134,175.5-242.5,151l195.8-842.6L985,1540.7l-216.9-51.8L754.6,604l-201.5,831.1l-197.7-48l223-917.6
 c14.7-60.6,75.4-98,136.1-83.9L912,431.2l23,846.5l395.4-744.8l311,71L1440.5,1443.4z"
      />
    </g>
  </svg>
);

export const ManncoinBanner = ({ height = '64px', width = '416px', color = secondaryColor, ...props }: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2598.3 843.1" enableBackground="new 0 0 2598.3 843.1" width={width} height={height} {...props}>
    <g>
      <path
        fill="#FFFFFF"
        d="M2208.6,360.1c0-13.2,0-26.4,0-39.7c10.4,0.2,20.8-0.4,31.2,0.4c6.9,0.5,14,4,17.1,10.6
 c4,9,2,19.2,2.5,28.7C2242.5,360.1,2225.6,360.1,2208.6,360.1z"
      />
      <path
        fill="#FFFFFF"
        d="M617.7,390.6c16.3-12.2,37.4-15.9,57.4-15.8c21.1-0.3,43.4,3.6,60.5,16.7c16.1-12,36.7-15.7,56.3-16
 c18.1-0.2,36.9,1.8,53.1,10.6c13.6,7.2,24,19.8,29.2,34.2c4.8,12.9,6.4,26.7,6.1,40.4c0,39.3,0,78.7,0,118c-9.4,0-18.7,0.1-28.1,0
 c-6.3-0.6-13-2.5-17.4-7.4c-4.1-4.4-5.7-10.7-5.6-16.6c0-33.4,0-66.8,0-100.2c0.1-9.4-2-19.5-9-26.3c-6.7-6.3-16.3-8-25.1-8.2
 c-8.8,0-18.2,1.4-25.1,7.2c-6.6,5.6-9.1,14.6-9.3,23c0,42.6,0,85.2,0,127.8c-16.9,0-33.9,0-50.8,0c0-41.5,0-83,0-124.6
 c-0.2-8.9-1.9-18.7-8.4-25.3c-6.8-6.9-17-8.9-26.4-8.8c-8.8,0-18.5,1.6-25.2,7.8c-5.9,5.2-8.2,13.3-8.6,21c0,43.3,0,86.6,0,129.9
 c-17.2,0-34.3,0-51.5,0c0-32.1,0-64.3,0-96.4c0.1-17.4-0.9-35,3.5-52C596.9,414.3,605.1,399.9,617.7,390.6z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M987.2,388.8c17.1-12.2,39-14.5,59.5-14c17.6,0.3,35.6,2.3,51.6,10c10.5,5,19.6,13.5,24,24.4
   c3.2,7.4,4.3,15.4,4.4,23.4c-0.1,22.9,0.1,45.8-0.1,68.7c-0.4,17.8-4.8,36.2-16.2,50.3c-12.6,15.9-32.3,24.1-51.7,27.7
   c-18.1,3.1-37,4.5-55-0.1c-14.5-3.6-28.3-11.9-36.4-24.6c-10.5-16.5-11.9-37.6-6.6-56.2c3.7-13.2,13.1-24.3,25-30.8
   c19.3-10.7,41.7-12.8,63.2-15.4c9.4-1.8,20.5-3.8,26.1-12.5c4.4-7.2-0.4-16.6-7.7-19.7c-10.5-4.5-22.4-4.6-33.6-3.2
   c-8,1-16.9,4.7-19.4,13.1c-16.9-0.1-33.7,0.1-50.6-0.1C966.1,414,973.8,398.2,987.2,388.8z M1049.9,486.8
   c-11.6,3.2-24.5,3.9-34.1,12c-10.5,8.5-10.7,25.8-1.8,35.5c7.6,7.3,19.2,7,28.9,5.5c11.9-1.8,23.3-8.8,28.9-19.6
   c7.2-13,5.6-28.3,5.7-42.6C1068.6,481.3,1059.3,484.3,1049.9,486.8z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M1257.4,378.3c13.2-3.5,27-4,40.5-3.5c14.7,0.9,29.6,3.7,42.4,11.2c11.7,6.4,20.7,17.1,26,29.3
 c5.1,11.7,7.2,24.4,7.7,37c0,41.9,0,83.7,0,125.6c-8.6,0-17.2,0-25.8,0c-6.4-0.3-13.2-1.9-18.1-6.2c-4.8-4.3-6.7-10.8-6.8-17.1
 c0-34.4,0-68.9,0-103.3c-0.3-9-2.8-18.7-9.9-24.7c-7.3-6.3-17.4-7.6-26.7-7.4c-8.9,0.2-18.6,2.4-24.8,9.3
 c-5.5,6.1-7.2,14.7-7.2,22.7c0,42.2,0,84.4,0,126.6c-17.2,0-34.3,0-51.5,0c0-34.9,0-69.8,0-104.7c0-14.2-0.1-28.6,3.2-42.4
 c2.9-12.5,8.5-24.6,17.5-33.8C1232.9,387.5,1244.9,381.5,1257.4,378.3z"
      />
      <path
        fill="#FFFFFF"
        d="M1471.2,395.7c16.5-16.1,40.6-21,62.9-21c21.6-0.3,44.8,3.5,61.8,17.9c13.9,11.8,20.9,29.7,23.1,47.4
 c1.7,12.7,1.3,25.6,1.3,38.4c0,33.2,0,66.3,0,99.5c-8.6,0-17.2,0-25.7,0c-6.7-0.3-13.7-2-18.9-6.5c-4.7-4.6-6.5-11.4-6.6-17.8
 c0-34.5,0-68.9,0-103.4c-0.4-8.5-2.9-17.6-9.6-23.4c-7.3-6.3-17.3-7.7-26.6-7.5c-9.2,0.2-19.1,2.5-25.4,9.6
 c-5.6,6.4-6.9,15.2-7,23.4c0,41.9,0,83.7,0,125.6c-17.1,0-34.1,0-51.1,0c0-41.5,0-83,0-124.5
 C1450.4,432.6,1455.9,410.7,1471.2,395.7z"
      />
      <path
        fill="#FFFFFF"
        d="M1733.4,387.8c19.7-11.6,43.4-14.3,65.8-12.6c20,1.6,40.5,8.6,54.1,24c10.3,11.3,16.2,26.1,19.1,41
 c-18.4,0-36.8,0.1-55.2,0c-3.2-7.6-8.9-14.3-16.5-17.7c-8.9-3.9-19.3-4.1-28.6-1.3c-8,2.5-14.6,8.4-18.5,15.7
 c-7,12.7-8,27.5-8.5,41.6c0.3,11.2,1,22.6,4.8,33.3c3.2,9.6,9.7,18.5,19.1,22.7c12.5,5.3,27.9,4.7,39.2-3.1
 c5.5-3.4,8.9-9.4,14.8-12.3c3.5-2,7.5-2.8,11.5-2.8c12.6,0,25.1,0,37.7,0c-3.7,17.7-12.2,34.8-25.9,46.8
 c-10.3,9.2-23.3,15.1-36.8,17.6c-10.4,2.1-21.2,1.9-31.8,1.5c-19.9-1.1-40.3-7.3-55.1-21.1c-16.8-15.7-24.5-38.6-27.3-61
 c-2.8-24.2-1.9-49.3,6.2-72.4C1707.5,411.4,1718.4,396.6,1733.4,387.8z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M2022,375.9c23.7-2.8,49.3-1.4,70.4,10.9c15.9,9.2,27,25.1,32.6,42.4c7.2,22.2,8,45.9,6.1,69
   c-2.1,21.6-8.3,44-23.6,60.1c-14.2,15.5-35.3,22.6-55.7,23.9c-21.1,1.4-43.4-0.6-62-11.4c-14.5-8.3-25.4-22.2-31.2-37.8
   c-8.3-21.6-9.5-45.1-8.2-67.9c1.4-22.4,6.7-45.8,21.5-63.5C1984.1,386.5,2003,378.3,2022,375.9z M2036.5,419.4
   c-6.3,0.6-12.5,2.6-17.6,6.5c-6.4,4.7-10.3,11.8-13,19.1c-4.3,12.7-5,26.3-4.6,39.7c0.6,10.5,2,21.2,6.2,30.9
   c3.3,7.5,8.7,14.5,16.2,18.1c9.5,4.6,21,4.8,30.8,0.9c7-2.8,12.5-8.6,16.1-15.2c4.2-7.8,6.1-16.5,7.3-25.2
   c1.6-14.4,1.6-29.1-1.6-43.3c-2.3-9.6-6.5-19.4-14.7-25.3C2054.6,420.1,2045.2,418.7,2036.5,419.4z"
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M2363.4,394.5c13.8-12.9,33.1-18.1,51.6-19.4c15.5-0.9,31.3-0.3,46.2,4.1c13.8,4.1,26.9,12.1,35.2,24.1
 c9.8,13.6,13.4,30.6,14.5,47v127.6c-8.8,0-17.5,0.1-26.2,0c-6.4-0.4-13-2-17.9-6.4c-4.6-4.3-6.6-10.7-6.7-16.8
 c0-33.8,0-67.6,0-101.4c-0.1-8.5-1.7-17.6-7.4-24.2c-6.2-7.2-16.1-9.6-25.2-9.8c-9.3-0.2-19.4,1.1-26.7,7.4
 c-6.8,5.8-9,15.1-9.2,23.6c0,42.6,0,85.1,0,127.7c-17.2,0-34.3,0-51.5,0c0-34.9,0-69.8,0-104.7c-0.1-13.3,0-26.8,2.7-39.9
 C2345.8,418.8,2352.3,404.5,2363.4,394.5z"
      />
      <path
        fill="#FFFFFF"
        d="M2208.6,377.5c10.4,0.2,20.9-0.4,31.2,0.4c5.5,0.5,11.1,2.7,14.7,7.1c3.9,4.7,4.7,11.2,4.9,17.2
 c0,58.6,0,117.1,0,175.7c-16.9,0-33.9,0-50.8,0C2208.6,511.1,2208.6,444.3,2208.6,377.5z"
      />
    </g>
    <g>
      <path
        fill={color}
        d="M517.5,490.1l6-27.1l-47.4-12.7L504.9,328l-241-57.9c-44.4-10.7-89.2,16.4-100.3,60.7l-11.8,46.9
 l-48.6-11.1l-6,26l48.9,11.4l-5.4,19.1l-47.4-11.1l-5.9,26.3l47.3,11.9l-29.1,123L346,630.7c44.5,10.6,89.3-16.3,100.9-60.6
 l12.1-46.3l48.6,11l5.9-26.8l-48.9-11.7l4.6-18.3L517.5,490.1z M403.8,549.6c-5.8,24.2-29.9,39.2-54.2,33.7l43.7-188.2L302,571.4
 l-48.4-11.6l-3-197.6l-45,185.6l-44.1-10.7l49.8-204.9c3.3-13.5,16.8-21.9,30.4-18.7l44.1,10.2l5.1,189l88.3-166.3l69.4,15.9
 L403.8,549.6z"
      />
    </g>
  </svg>
);
