import { AppBar, CircularProgress, Grid, Paper, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import { AccountBalance } from '@material-ui/icons';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useData } from '../../Hooks/useData';
import { Note } from '../Note/Notes';
import { ManncoinBusinessTheme } from '../../Theme/ManncoinTheme';
import { CustomerAvailableBalance } from '../CustomerAvailableBalance';
import { CustomerBalance } from '../CustomerBalance';
import { KycStatusUpdate } from '../KycStatusUpdate';
import { TransactionList } from '../TransactionList';
import { Documents } from '../Documents';
import { CustomerForm } from '../Customer/CustomerForm';

import { BusinessForm } from './BusinessForm';

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index} id={`customer-details-tabpanel-${index}`} aria-labelledby={`customer-details-tab-${index}`} {...other}>
    {value === index && children}
  </div>
);

function makeTabProps(index: any) {
  return {
    id: `customer-details-tab-${index}`,
    'aria-controls': `customer-details-tabpanel-${index}`,
  };
}

export const BusinessDetails: FC = () => {
  const [value, setValue] = useState(0);
  const { address } = useParams<{ address: string }>();
  const { loading, error, data, refresh } = useData(`/business/${address}`);
  const { loading: loading2, error: error2, data: data2, refresh: refresh2 } = useData(`/account/${address}`);
  const [account, setAccount] = useState<any>();
  useEffect(() => {
    setAccount(data2);
  }, [data2]);
  if (error || error2)
    return (
      <div>
        <div>An error occured while loading {address}</div>
      </div>
    );
  if (loading || !data || loading2 || !data2)
    return (
      <Grid container alignItems="center" alignContent="center">
        <CircularProgress color="primary" />
      </Grid>
    );

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={ManncoinBusinessTheme}>
      <Paper style={{ padding: '1rem', flex: 1 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4">{address}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {data.tradingName || 'UNDEFINED'}, {data.companyRegisteredName || 'UNDEFINED'}
            </Typography>
            <Typography variant="subtitle2">Registered: {new Date(account.created).toLocaleString() || 'UNDEFINED'}</Typography>
          </Grid>
          <Grid container direction="row">
            <Grid item style={{ flexBasis: '33%', marginTop: '1rem' }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h5">Balance</Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    <CustomerBalance address={address} color="secondary" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flexBasis: '33%', marginTop: '1rem' }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h5">Available Balance</Typography>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="h6">
                    <CustomerAvailableBalance address={address} color="secondary" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flexBasis: '33%', marginTop: '1rem' }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h5">Status</Typography>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="h6">
                    <KycStatusUpdate
                      address={address}
                      status={account.kycStatus}
                      onUpdate={() => {
                        refresh();
                        refresh2();
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AppBar position="relative" style={{ marginTop: '1rem' }} color="primary">
          <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Business" {...makeTabProps(0)} />
            <Tab label="Personal" {...makeTabProps(1)} />
            <Tab label="Documents" {...makeTabProps(2)} />
            <Tab label="Transactions" {...makeTabProps(3)} />
            <Tab label="Notes" {...makeTabProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BusinessForm address={address} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CustomerForm address={address} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Documents address={address} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TransactionList address={address} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Note referenceType="customer" referenceId={address} />
        </TabPanel>
      </Paper>
    </ThemeProvider>
  );
};
