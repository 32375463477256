import { useEffect, useState } from 'react';

import { API_ENDPOINT } from '../Constants';

type AmountResponse = {
  amount: number;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAvailableBalance = (address: string): { loading: boolean; error: string | undefined; data: any; refresh: () => void } => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<null | AmountResponse>();
  const [error, setError] = useState();
  const [rerun, setRerun] = useState(true);
  const refresh = () => {
    setRerun(true);
  };
  useEffect(() => {
    if (!loading && rerun) {
      setRerun(false);
      setLoading(true);
      fetch(`${API_ENDPOINT}/balance/${address}/available`)
        .then((res) => res.json())
        .then((json) => {
          setData(json);
        })
        .catch((err) => {
          console.debug(err);
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [rerun, loading]);
  return { loading, error, data, refresh };
};
