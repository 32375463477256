import React, { DragEvent, FC, useReducer } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type Props = {
  style?: CSSProperties;
  onDrop: (files: FileList) => void;
  children: React.ReactNode;
};

type ActionType = {
  type: string;
  inDropZone?: boolean;
  dropDepth?: number;
  files?: [];
};
// type StateType = {
//   type: string;
//   inDropZone: boolean;
//   dropDepth: number;
//   fileList: [];
// };

export const FileUploader: FC<Props> = ({ children, style, onDrop }: Props) => {
  // const [uploaderInput, setUploaderInput] = useState<HTMLInputElement | null>(null);
  // const [isDragValid, setIsDragValid] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reducer = (state: any, action: ActionType) => {
    switch (action.type) {
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth };
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };
  const [data, dispatch] = useReducer(reducer, { dropDepth: 0, inDropZone: false, fileList: [] });
  const handleDragEnter = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth + 1 });
  };
  const handleDragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: data.dropDepth - 1 });
    if (data.dropDepth - 1 > 0) return;
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
  };
  const handleDrop = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    const { files } = evt.dataTransfer;
    onDrop(files);
  };
  const handleDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };
  return (
    <div style={{ position: 'relative', ...style }} onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
      {
        <div
          style={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,${data.inDropZone ? '0.3' : '0'})`,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <PublishIcon style={{ height: '50%', width: '50%', opacity: data.inDropZone ? 1 : 0 }} />
          </div>
        </div>
      }
      {children}
    </div>
  );
};
