import { Grid } from '@material-ui/core';
import React, { FC } from 'react';

import { KycStats } from './KycStats';

export const Dashboard: FC = () => (
  <div>
    <Grid container spacing={10}>
      <Grid item>
        <KycStats />
      </Grid>
    </Grid>
  </div>
);
