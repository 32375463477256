import React, { FC, useState } from 'react';
import { Button, Grid, TextField, createStyles, makeStyles } from '@material-ui/core';

import { useUserContext } from '../UserContext';
import { primaryColor } from '../Theme/ManncoinTheme';

import { ManncoinLogo } from './ManncoinLogo';

const useStyles = makeStyles(() => createStyles({ textInput: { color: '#FFF' } }));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Login: FC = (props: any) => {
  const classes = useStyles();
  const { login } = useUserContext();
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    const result = await login(username, password);
    if (!result.success) {
      setError(result.message || '');
    }
  };

  return (
    <Grid container justify="center" alignContent="center" {...props} style={{ ...props.style, height: '100vh', width: '100%', background: primaryColor }}>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" wrap="wrap">
          <Grid container spacing={2} justify="center" direction="column">
            <Grid item style={{ height: '20rem' }}>
              <ManncoinLogo style={{ height: '13rem', margin: '0 auto' }} />
            </Grid>
            <Grid item>
              <TextField
                placeholder="Username"
                variant="standard"
                value={username}
                onChange={(evt) => {
                  setUsername(evt.target.value);
                }}
                required
                color="secondary"
                tabIndex={1}
                inputProps={{ className: classes.textInput }}
                autoFocus={true}
              />
            </Grid>
            <div style={{ height: '2em' }}></div>
            <Grid item>
              <TextField
                type="password"
                placeholder="Password"
                variant="standard"
                color="secondary"
                value={password}
                inputProps={{ className: classes.textInput }}
                onChange={(evt) => {
                  setPassword(evt.target.value);
                }}
                required
                tabIndex={2}
              />
            </Grid>
            <Button tabIndex="-1" type="submit" style={{ height: 0, width: 0, position: 'absolute' }}></Button>
            {error.length > 0 ? <Grid item>{error}</Grid> : ''}
            <Grid item style={{ height: '20rem' }}></Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
