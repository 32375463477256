/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { API_ENDPOINT } from '../Constants';

export const useData = (endpoint: string, params?: { [key: string]: any }): any => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<any>();
  const [rerun, setRerun] = useState(true);
  const refresh = () => {
    if (!loading) {
      setRerun(true);
    }
  };
  let paramString = '';
  if (params && Object.keys(params).length > 0) {
    paramString = `?${new URLSearchParams(params)}`;
  }
  useEffect(() => {
    if (!loading && rerun) {
      console.debug(`Loading data ${API_ENDPOINT}${endpoint}${paramString}`);
      setRerun(false);
      setLoading(true);
      setError(undefined);
      fetch(`${API_ENDPOINT}${endpoint}${paramString}`, {})
        .then((res) => res.json())
        .then((json) => {
          setData(json);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [endpoint, rerun, loading, paramString]);
  return { loading, error, data, refresh };
};
