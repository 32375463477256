import { Avatar, CircularProgress, Container, Divider, Grid, Paper, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchResultItemType } from '../Hooks/useSearch';
import { useUserContext } from '../UserContext';

import { AccountStatus } from './AccountStatus';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '1rem',
      marginTop: '2rem',
      '&:hover': { background: 'rgba(0,0,0,.1)' },
      cursor: 'pointer',
    },
    title: {},
    customerTitle: { background: 'rgba(41,159,255, .2)' },
    businessTitle: { background: '#E50028', color: '#424C4F' },
    details: { paddingLeft: '1rem', marginTop: 0, flexGrow: 1 },
    businessBg: { background: 'rgba( 229, 0, 40, 1)', color: 'rgba( 66, 76, 79, 1)' },
    //customerBg: { background: 'rgba( 229, 0, 40, 1)', color: 'rgba( 66, 76, 79, 1)' },
    customerBg: { background: 'rgba(41,159,255, 1)', color: 'rgba( 255, 242, 0, 1)' },
  }),
);

const SearchResult: FC<SearchResultItemType> = ({ address, name, status, accountType }: SearchResultItemType) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Paper key={address} className={classes.root} elevation={4}>
      <Grid
        container
        direction="column"
        onClick={() => {
          history.push(`/${accountType === 'customer' ? 'customer' : 'business'}/${address}`);
        }}
      >
        <Grid container direction="row">
          <Grid item>
            <Avatar className={accountType === 'customer' ? classes.customerBg : classes.businessBg}>{accountType === 'customer' ? 'C' : 'B'}</Avatar>
          </Grid>
          <Grid item direction="column" className={classes.details}>
            <Typography variant="h6" className={classes.title}>
              {name}
            </Typography>
            <Typography variant="subtitle1">{address}</Typography>
          </Grid>
          <Grid>
            <AccountStatus status={status} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const SearchResults: FC = () => {
  const { search } = useUserContext();
  const { loading, results, error } = search;
  if (loading) {
    return <CircularProgress color="primary" />;
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (results && results.entities.length === 0) {
    return <div>No results found</div>;
  }
  if (results) {
    return (
      <Container maxWidth="lg" style={{ padding: '1rem' }}>
        {results.entities.map((result, index) =>
          index > 0 ? <Divider /> && <SearchResult key={result.address} {...result} /> : <SearchResult key={result.address} {...result} />,
        )}
      </Container>
    );
  }

  return <div>An unkown error has occured</div>;
};
