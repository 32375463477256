import { Avatar, Divider, Grid, Paper, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import { useSocket } from '../../SocketContext';
import { timeSince } from '../../Utils/timeSince';

type Props = {
  referenceType: string;
  referenceId: string;
};

interface NoteType {
  id: number;
  referenceType: string;
  referenceId: string;
  accountId: number;
  name: string;
  note: string;
  created: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '1rem',
    },
    usericon: {
      margin: '1rem',
    },
    comment: {
      padding: '1rem',
    },
  }),
);

function getLetters(name: string): string {
  const parts = name.split(' ');
  if (parts.length > 1) {
    return `${parts[0][0].toUpperCase()}${parts[1][0].toUpperCase()}`;
  } else {
    return `${parts[0][0].toUpperCase()}${parts[0][parts[0].length - 1].toUpperCase()}`;
  }
}

export const NoteList: FC<Props> = ({ referenceId, referenceType }: Props) => {
  const classes = useStyles();
  const socket = useSocket();
  const [offset] = useState(0);
  const [limit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<NoteType[]>([]);
  const [error, setError] = useState<null | string>(null);
  const [typing, setTyping] = useState<string[]>([]);
  useEffect(() => {
    let active = true;
    (async () => {
      console.debug(`Fetching comments`);
      const res = await fetch(`/api/v1/note/${referenceType}/${referenceId}`);
      const data = await res.json();
      if (active) {
        if (!data.ok) {
          setError(data.message);
        }
        setNotes(data.entities);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
    };
  }, [offset, limit]);
  useEffect(() => {
    socket.emit('joinRoom', `note/${referenceType}/${referenceId}`);
    socket.on('typing', (name: string) => {
      if (typing.indexOf(name) === -1) {
        const newTyping = [...typing, name];
        setTyping(newTyping);
      }
    });
    socket.on('stopTyping', (name: string) => {
      const newTyping = typing.filter((i) => i !== name);
      setTyping(newTyping);
    });
    return () => {
      socket.emit('leaveRoom', `note/${referenceType}/${referenceId}`);
    };
  }, []);
  useEffect(() => {
    socket.on('note', (newNote: NoteType) => {
      setNotes([newNote, ...notes]);
    });
  }, [notes]);

  if (error && error.length > 0) return <div>{error}</div>;
  if (loading) return <div>Loading</div>;
  if (notes.length === 0) return <div>No notes yet!</div>;

  return (
    <Paper className={classes.root}>
      {typing.length > 0 ? (
        <Typography>
          {typing.join(', ')} {typing.length > 1 ? 'are' : 'is'} typing
        </Typography>
      ) : null}
      {notes.map(({ id, name, note, created }, i) => (
        <>
          {i !== 0 ? <Divider /> : null}
          <Grid key={id} container wrap="nowrap" spacing={2}>
            <Avatar className={classes.usericon}>{getLetters(name)}</Avatar>
            <Grid item zeroMinWidth xs>
              <Grid container direction="column" className={classes.comment}>
                <Typography variant="subtitle2">{name}</Typography>
                <Typography variant="body1">
                  {note.split('\n').map((text, idx) => (
                    <>
                      {idx > 0 ? <br /> : null}
                      <span key={idx}>{text}</span>
                    </>
                  ))}
                </Typography>
                <Typography variant="body2">
                  {timeSince(new Date(created))} ({new Date(created).toLocaleString()})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Paper>
  );
};
