import { Button, CircularProgress, Grid, TextField, TextFieldProps, createStyles, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';

import { API_ENDPOINT } from '../../Constants';

type BusinessData = {
  address: string;
  address1: string;
  address2: string;
  companyRegisteredName: string;
  companyRegistrationNumber: string;
  country: string;
  dateofIncorporation: string;
  id: number;
  postcode: string;
  tradingName: string;
};

type Props = {
  address: string;
  onUpdated?: (data: BusinessData) => void;
};
const useStyles = makeStyles(() =>
  createStyles({
    gridItem: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
    mainContainer: {
      padding: '1rem',
    },
  }),
);
const textFieldDefaults: TextFieldProps = {
  fullWidth: true,
  variant: 'outlined',
  color: 'primary',
  inputProps: { style: { padding: '1rem' } },
};

const DEFAULT_DATA = {
  address: '',
  address1: '',
  address2: '',
  companyRegisteredName: '',
  companyRegistrationNumber: '',
  country: '',
  dateofIncorporation: '',
  id: 0,
  postcode: '',
  tradingName: '',
};

function RenderDate(dateStr: string): string {
  const d = new Date(dateStr);
  return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear().toString()}`;
}
export const BusinessForm: FC<Props> = ({ address, onUpdated }: Props) => {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [original, setOriginal] = useState<BusinessData>(DEFAULT_DATA);
  const [business, setBusiness] = useState<BusinessData>(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    let active = true;
    (async () => {
      const res = await fetch(`${API_ENDPOINT}/business/${address}`);
      const data: BusinessData | null = await res.json();
      if (data) {
        data.dateofIncorporation = RenderDate(data.dateofIncorporation);
        setOriginal(data);
        setBusiness(data);
      }
      setLoading(false);
    })();
    return () => {
      active = false;
    };
  }, []);
  useEffect(() => {
    setIsDirty(JSON.stringify(business) !== JSON.stringify(original));
  }, [original, business]);
  const handleChange = (name: string) => (evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (business) setBusiness({ ...business, [name]: evt.currentTarget.value });
  };
  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setBusy(true);
    const payload: { [key: string]: unknown } = {};
    if (!business) return false;
    if (!original) return false;
    Object.keys(original).forEach((key: string) => {
      if (original[key as keyof BusinessData] !== business[key as keyof BusinessData]) {
        payload[key] = business[key as keyof BusinessData];
      }
    });
    fetch(`${API_ENDPOINT}/business/${address}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => {
        enqueueSnackbar(`Business Details Updated`, { variant: 'success' });
        setOriginal(business);
        if (onUpdated) onUpdated(business);
      })
      .catch((err) => {
        enqueueSnackbar(`Business Details Update Failed`, { variant: 'error' });
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => setBusy(false), 1000);
      });
  };
  if (loading)
    return (
      <Grid container style={{ height: '100%', width: '100%' }} alignContent="center" alignItems="center" justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={classes.mainContainer} direction="column">
        <Grid item className={classes.gridItem}>
          <TextField label="Trading Name" value={business.tradingName} onChange={handleChange('tradingName')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Registered Name" value={business.companyRegisteredName} onChange={handleChange('companyRegisteredName')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Company Number" value={business.companyRegistrationNumber} onChange={handleChange('companyRegistrationNumber')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Date of Incorporation" value={business.dateofIncorporation} onChange={handleChange('dateofIncorporation')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Address Line 1" value={business.address1} onChange={handleChange('address1')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Address Line 2" value={business.address2} onChange={handleChange('address1')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Country" value={business.country} onChange={handleChange('country')} {...textFieldDefaults} />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField label="Post Code" value={business.postcode} onChange={handleChange('postcode')} {...textFieldDefaults} />
        </Grid>
        <Grid container direction="row">
          <div style={{ flexGrow: 1 }} />
          {isDirty && (
            <Button
              onClick={() => {
                const newData: any = {};
                if (original) {
                  Object.keys(original).forEach((key) => {
                    const value = original[key as keyof BusinessData];
                    newData[key] = value || '';
                  });
                  setBusiness(newData);
                }
              }}
            >
              Reset
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit" disabled={busy || !isDirty}>
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
