import { AppBar, Fade, IconButton, Theme, Toolbar, createStyles, makeStyles } from '@material-ui/core';
import { MenuIcon } from '@material-ui/data-grid';
import React, { FC } from 'react';
import clsx from 'clsx';

import { useUserContext } from '../UserContext';
import { ManncoinBanner } from '../Icons/manncoin';

import { UserHeaderIcon } from './UserHeaderIcon';
import { SearchBox } from './SearchBox';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    offset: theme.mixins.toolbar,
    hide: {
      display: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }),
);

export const Header: FC = () => {
  const classes = useStyles();
  const { setShowDraw, showDraw } = useUserContext();
  const handleDraw = () => {
    setShowDraw(!showDraw);
  };
  return (
    <Fade in timeout={500}>
      <div>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: showDraw,
          })}
        >
          <Toolbar>
            <IconButton color="inherit" aria-label="menu" onClick={handleDraw} className={clsx(classes.menuButton, showDraw && classes.hide)}>
              <MenuIcon />
            </IconButton>
            <ManncoinBanner height="64px" width="200px" />
            <div style={{ flexGrow: 1 }}></div>
            <SearchBox />
            <div style={{ flexGrow: 1 }}></div>
            <UserHeaderIcon />
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />
      </div>
    </Fade>
  );
};
