import React, { createContext, useContext, useState } from 'react';
import { Socket, io } from 'socket.io-client';

const SocketContext = createContext<Socket>({} as Socket);

export const useSocket = (): Socket => useContext<Socket>(SocketContext);

export const SocketContextProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<Socket>({} as Socket);
  React.useEffect(() => {
    const socketIo = io('/', {
      transports: ['websocket'],
    });
    setSocket(socketIo);
    socketIo.on('connected', () => {
      console.log('connected');
    });
    socketIo.on('error', (err: Error) => {
      console.log(err);
    });
    return () => {
      socketIo.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
